<script>
import { mapActions, mapGetters } from 'vuex'
// import { solutionTypeFormatter } from '@/support/utils/solutionTypeFormatter'

export default {
  name: 'SolutionsTypes',
  components: {
    Action: () => import('@/components/general/Action'),
    Dropdown: () => import('@/components/general/Dropdown'),
    Datatable: () => import('@/components/general/Datatable'),
    FilterList: () => import('@/components/general/FilterList'),
    Pagination: () => import('@/components/general/Pagination'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {
          active: 'desc',
          name: 'asc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      },

      modalRemoveOpen: false,
      modalDeactivateOpen: false,
      solutionId: null,
      solutionsTypes: [],
      modalSessionsFiltersOpen: false,
      filteredFormLabels: {
        active: [
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.1'),
            value: 'true'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.1.option.2'),
            value: 'false'
          }
        ],

        showOnPortal: [
          {
            text: this.$t('global:form.tracks.yes'),
            value: '1'
          },
          {
            text: this.$t('global:form.tracks.no'),
            value: '0'
          }
        ],

        hasCertificate: [
          {
            text: this.$t('solutions.manage:filtered-form.input.3.option.1'),
            value: '1'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.3.option.2'),
            value: '0'
          }
        ],

        hasSurvey: [
          {
            text: this.$t('solutions.manage:filtered-form.input.4.option.1'),
            value: '1'
          },
          {
            text: this.$t('solutions.manage:filtered-form.input.4.option.2'),
            value: '0'
          }
        ],

        enrollmentClosed: [
          {
            text: this.$t('global:form.tracks.yes'),
            value: '"closed"'
          },
          {
            text: this.$t('global:form.tracks.no'),
            value: '"open"'
          }
        ]
      },

      filteredForm: {
        active: null,
        show_on_portal: null,
        has_certificate: null,
        has_survey: null,
        enrollment_closed: null
      },

      tabLinks: [
        {
          location: { name: 'solutions.index' },
          text: this.$t('solutions.tabs:link.1')
        },
        {
          location: { name: 'solutions.types' },
          text: this.$t('solutions.tabs:link.2')
        },
        {
          location: { name: 'solutions.offers' },
          text: this.$t('solutions.tabs:link.3')
        }
      ]
    }
  },

  computed: {
    ...mapGetters([ 'activeModules' ])
  },

  watch: {
    'pagination.page' () {
      this.getSolutionTypeList()
    }
  },

  created () {
    this.filteredForm = this.pagination.filter

    this.getSolutionTypeList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSolutionsTypesList',
      'attemptManageSolutionType',
      'attemptRemoveSolution',
      'attemptToggleActiveSolutionType'
    ]),

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    createNewSolutionType () {
      // this.attemptManageSolutionType(null)
      this.$router.push({ name: 'solutions.types.manage' })
    },

    edit (solutionTypeId, tab = 1) {
      this.setFetching(true)

      this.attemptManageSolutionType(solutionTypeId).then(() => {
        this.$router.push({
          name: 'solutions.types.manage',
          params: {
            id: solutionTypeId,
            tab: tab
          }
        })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    toggleActive (solutionTypeId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleActiveSolutionType(solutionTypeId).then(() => {
        this.getSolutionTypeList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('solution_types:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('solution_types:feedback.toggle.deactivate.success') })
        }
      }).catch((e) => {
        console.warn(e)
        this.setFeedback({ message: this.$t('global.error') })
      }).finally(() => {
        this.setFetching(false)
        this.modalDeactivateOpen = false
      })
    },

    remove () {
      // this.modalRemoveOpen = false
      // this.setFetching(true)
      // this.attemptRemoveSolution(this.solutionId).then(() => {
      //   this.setFeedback({ message: this.$t(`solutions:feedback.remove.success`) })
      //   this.getSolutionTypeList()
      // }).catch(() => {
      //   this.setFeedback({ message: this.$t(`solutions:feedback.remove.error`) })
      // }).finally(() => {
      //   this.setFetching(false)
      // })
    },

    deactivate () {
      this.toggleActive(this.solutionId, true)
    },

    // confirmRemove (idSolution) {
    // this.solutionId = idSolution
    // this.modalRemoveOpen = true
    // },

    confirmDeactivate (idSolution) {
      this.solutionId = idSolution
      this.modalDeactivateOpen = true
    },

    cancelDeactivate () {
      this.modalDeactivateOpen = false
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    },

    // getErrorMessage (e) {
    //   if (e.data && e.data.error && e.data.error.message) {
    //     return e.data.error.message
    //   }

    //   return null
    // },

    getSolutionTypeList () {
      this.setFetching(true)

      this.attemptGetSolutionsTypesList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
        this.solutionsTypes = pagination.data
      }).finally(() => {
        this.setFetching(false)
      })
    },

    hasConfigs (props) {
      const alias = props.item?.Offer?.alias ?? ''

      return [
        'audio',
        'video'
      ].includes(alias)
    },

    openConfig (idSolution) {
      this.$router.push({
        name: 'solutions.config',
        params: { id: idSolution }
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('solutions:header.title')"
      :description="$t('solutions:header.description')"
      background="/assets/images/themes/default/png/solutions_banner.png"
    >
      <H1>QUALQUER COISA SOCORRO</H1>
      <Tabs
        slot="tabs"
        dark
        :links="tabLinks"
      />
    </ContentHeader>

    <FilterList>
      <Action
        slot="button"
        primary
        large
        fixed-width
        type="button"
        :text="$t('solution_types.list:btn.add')"
        @click="createNewSolutionType"
      />
    </FilterList>

    <div
      v-if="solutionsTypes.length > 0"
      class="center solutions-table"
    >
      <Datatable :items="solutionsTypes">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('solution_types.list:datatable.header.1') }}
            </th>

            <th
              class="th"
              width="170"
            >
              {{ $t('solution_types.list:datatable.header.2') }}
            </th>

            <th class="th">
              {{ $t('solution_types.list:datatable.header.3') }}
            </th>

            <th class="th text-center">
              {{ $t('solution_types.list:datatable.header.4') }}
            </th>

            <th
              class="th"
              width="40"
            />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td">
              <span class="td-text bolder">
                <span
                  class="color-ball"
                  :style="{backgroundColor: props.item.color}"
                />
                {{ props.item.name }}
              </span>
            </td>

            <td class="td">
              <span
                v-if="props.item.Offer"
                class="td-text"
              >{{ props.item.Offer.name }}</span>
            </td>

            <td class="td">
              <span class="td-text">/{{ props.item.alias }}/{URL_CURSO}</span>
            </td>

            <td class="td text-center">
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>

              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>

            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id, 1)"
                />

                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />

                <DropdownLink
                  v-else
                  :text="$t('global:deactivate')"
                  @click="confirmDeactivate(props.item.id)"
                />

                <DropdownLink
                  v-if="hasConfigs(props)"
                  :text="$t('global:configurations')"
                  @click="openConfig(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>

    <div
      v-else
      class="center solutions-table"
    >
      <p>Nenhuma solução encontrada para o termo pesquisado</p>
    </div>

    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('solutions.manage:remove.title')"
      :description="$t('solutions.manage:remove.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
    <ModalConfirm
      :active="modalDeactivateOpen"
      :title="$t('solution_types.manage:deactivate.title')"
      :description="$t('solution_types.manage:deactivate.description')"
      @confirmAction="deactivate"
      @cancelAction="cancelDeactivate"
    />
  </div>
</template>

<style>
.tooltip {
  color: #005EB8;
  z-index: 9999;
  font-size: 0.85rem;
  margin: 1px solid #005EB8;
}

.tooltip p {
  margin-top: 5px;
}

.tooltip.info .tooltip-inner, .popover-inner div {
  background: linear-gradient(to bottom, #00B1E6 0%,#00426E 100%);
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
  max-width: 500px;
  color: white;
}

.popover-inner div p {
  padding-bottom: 10px;
}
.popover-inner div span {
  display: block;
}

.tooltip .info .tooltip-arrow {
  border-color: #005EB8;
}

.warning {
  cursor: pointer;
  margin-left: 10px;
}

.form-buttons {
  display: flex;
  justify-content: space-around;
}

.color-ball {
  width: 22px;
  height: 22px;
  margin-right: 8px;
  border-radius: 20px;
  display: inline-block;
  transform: translateY(5px);
  border: 1px solid rgba(200, 200, 200, 0.5);
}
</style>
